// actions.ts

import { Dispatch } from 'redux';
import { handleApiRequest } from 'Utils/handleApiRequest';
import { Api } from 'Utils/api';
import { ScopeData, ScopeSheet } from 'Containers/RocketScope/types';

interface IWorkScopeItem {
  id?: number;
  category: string;
  code_part_1: string;
  code_part_2: string;
  description: string;
  unit: string;
  rate: string;
  notes: string;
}

interface IWorkScope {
  tab_name: string;
  data: IWorkScopeItem[];
}

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const SET_CURRENT_SHEET = 'SET_CURRENT_SHEET';
export const SET_HAS_CHANGES = 'SET_HAS_CHANGES';
export const SET_IS_EDITING = 'SET_IS_EDITING';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_ROW = 'SET_SELECTED_ROW';
export const SET_CHANGES = 'SET_CHANGES';
export const SET_REMOVED = 'SET_REMOVED';
export const SET_NEW_CATEGORY = 'SET_NEW_CATEGORY';
export const SET_IS_NEW_CATEGORY_OPEN = 'SET_IS_NEW_CATEGORY_OPEN';
export const SET_NEW_CATEGORY_FORM_ERRORS = 'SET_NEW_CATEGORY_FORM_ERRORS';
export const SET_SAVE_ERROR = 'SET_SAVE_ERROR';
export const SET_SCOPE_SHEETS = 'SET_SCOPE_SHEETS';
export const SET_HAS_FILE_UPLOAD = 'SET_HAS_FILE_UPLOAD';

function parseResponse(
  response: { data: { id: number; tab_name: string; work_scope_items: IWorkScopeItem[] }[] },
  dispatch: Dispatch
) {
  const newSheetData: ScopeSheet[] = [];
  response.data.forEach((sheet: { id: number; tab_name: string; work_scope_items: IWorkScopeItem[] }): void => {
    newSheetData.push({
      id: sheet.id,
      name: sheet.tab_name,
      data: sheet.work_scope_items.map((item) => ({
        id: item.id,
        category: item.category,
        codePart1: item.code_part_1 || '',
        codePart2: item.code_part_2 || '',
        description: item.description,
        unit: item.unit,
        rate: item.rate || '',
        notes: item.notes || '',
      })),
    });
  });
  dispatch({ type: SET_SCOPE_SHEETS, payload: newSheetData });
}

export const fetchScopeData = (companyId: number) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_DATA_REQUEST });
  try {
    const response = await handleApiRequest(dispatch, Api.get(`work-scope/${companyId}`));

    if (response?.data) {
      parseResponse(response, dispatch);
      dispatch({ type: FETCH_DATA_SUCCESS });
    } else {
      dispatch({ type: FETCH_DATA_FAILURE, error: 'No data returned' });
    }
  } catch (error) {
    dispatch({ type: FETCH_DATA_FAILURE, error });
  }
};

export const changeScope =
  (
    companyId: number,
    changeData: { sheetId: number; name: string; changes: ScopeData[] }[],
    resolve: () => void,
    reject: () => void
  ) =>
  async (dispatch: any) => {
    try {
      const data = changeData.map((change) => ({
        id: change.sheetId,
        name: change.name,
        data: change.changes.map((row) => ({
          id: row.id > 0 ? row.id : 0,
          category: row.category,
          code_part_1: row.codePart1,
          code_part_2: row.codePart2,
          description: row.description,
          unit: row.unit,
          rate: row.rate,
          notes: row.notes,
        })),
      }));

      const response = await handleApiRequest(dispatch, Api.put(`work-scope/${companyId}`, data));
      if (response?.data) {
        parseResponse(response, dispatch);
      }
      resolve();
    } catch (_) {
      reject();
    }
  };

export const deleteScope =
  (
    companyId: number,
    removedData: { sheetId: number; removed: ScopeData[] }[],
    resolve: () => void,
    reject: () => void
  ) =>
  async (dispatch) => {
    try {
      const data = removedData.map((remove) => ({
        id: remove.sheetId,
        removed: remove.removed,
      }));
      await handleApiRequest(dispatch, Api.post(`work-scope/${companyId}/delete`, data));
      resolve();
    } catch (error) {
      reject();
    }
  };

function getRequestData(scopeData: ScopeData[][], sheetNames: string[]) {
  const requestData: IWorkScope[] = [];
  for (let i = 0; i < scopeData.length; i += 1) {
    requestData.push({
      tab_name: sheetNames[i],
      data: scopeData[i].map((row) => ({
        id: row.id || null,
        category: row.category,
        code_part_1: row.codePart1,
        code_part_2: row.codePart2,
        description: row.description,
        unit: row.unit,
        rate: row.rate,
        notes: row.notes,
      })),
    });
  }
  return requestData;
}

export const replaceSingleSheet =
  (
    companyId: number,
    sheetId: number,
    scopeData: ScopeData[],
    sheetName: string,
    resolve: () => void,
    reject: (err: string) => void
  ) =>
  async (dispatch: any, _, utils: any) => {
    try {
      const requestData = {
        id: sheetId,
        tab_name: sheetName,
        data: scopeData.map((row) => ({
          id: row.id || null,
          category: row.category,
          code_part_1: row.codePart1,
          code_part_2: row.codePart2,
          description: row.description,
          unit: row.unit,
          rate: row.rate,
          notes: row.notes,
        })),
      };
      const response = await handleApiRequest(
        dispatch,
        utils.Api.post(`work-scope/${companyId}/replace-scope`, requestData)
      );
      if (response?.data) {
        parseResponse(response, dispatch);
        resolve();
      } else {
        reject('Error uploading file');
      }
    } catch (_) {
      reject('Error uploading file');
    }
  };

export const replaceScope =
  (
    companyId: number,
    scopeData: ScopeData[][],
    sheetNames: string[],
    resolve: () => void,
    reject: (err: string) => void
  ) =>
  async (dispatch: any, _, utils: any) => {
    try {
      const requestData = getRequestData(scopeData, sheetNames);
      const response = await handleApiRequest(
        dispatch,
        utils.Api.post(`work-scope/${companyId}/replace-scopes`, requestData)
      );
      if (response?.data) {
        parseResponse(response, dispatch);
        resolve();
      } else {
        reject('Error uploading file');
      }
    } catch (_) {
      reject('Error uploading file');
    }
  };

export const addScope =
  (companyId: number, scopeData: ScopeData[][], sheetNames: string[], resolve: () => void, reject: () => void) =>
  async (dispatch: any, _, utils: any) => {
    try {
      const requestData = getRequestData(scopeData, sheetNames);
      const response = await handleApiRequest(
        dispatch,
        utils.Api.post(`work-scope/${companyId}/add-scopes`, requestData)
      );
      if (response?.data) {
        parseResponse(response, dispatch);
      }
      resolve();
    } catch (_) {
      reject();
    }
  };

export const setIsEditing = (isEditing: boolean) => ({ type: SET_IS_EDITING, payload: isEditing });
export const setHasChanges = (hasChanges: boolean) => ({ type: SET_HAS_CHANGES, payload: hasChanges });
export const setLoading = (loading: boolean) => ({ type: SET_LOADING, payload: loading });
export const setIsNewCategoryOpen = (isOpen: boolean) => ({ type: SET_IS_NEW_CATEGORY_OPEN, payload: isOpen });
export const setSelectedRow = (selectedRow: number | null) => ({ type: SET_SELECTED_ROW, payload: selectedRow });
export const setChanges = (changes: { [sheet: number]: number[] }) => ({ type: SET_CHANGES, payload: changes });
export const setRemoved = (removed: { [sheet: number]: number[] }) => ({ type: SET_REMOVED, payload: removed });
export const setNewCategory = (newCategory: string) => ({ type: SET_NEW_CATEGORY, payload: newCategory });
export const setNewCategoryFormErrors = (errors: { [errorType: string]: string[] }) => ({
  type: SET_NEW_CATEGORY_FORM_ERRORS,
  payload: errors,
});
export const setSaveError = (error: string) => ({ type: SET_SAVE_ERROR, payload: error });
export const setCurrentSheet = (currentSheet: number) => ({ type: SET_CURRENT_SHEET, payload: currentSheet });
export const setScopeSheets = (scopeSheets: ScopeSheet[]) => ({ type: SET_SCOPE_SHEETS, payload: scopeSheets });
export const setHasFileUpload = (hasFileUpload: boolean) => ({ type: SET_HAS_FILE_UPLOAD, payload: hasFileUpload });
